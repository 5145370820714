import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { APIHeaders, BASEURL } from "../config";

const credentials = {
  callerid: window.localStorage.getItem("spiral_username"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const authAPI = createApi({
  reducerPath: "authPath",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    headers: APIHeaders,
    prepareHeaders: (headers, {}) => {
      //   const token = credentials.token;
      //   if (token) {
      //     headers.set("Version", `0.1`);
      //     return headers;
      //   }
    },
  }),
  endpoints: (build) => ({
    createAccount: build.mutation({
      query: (data) => ({
        url: "signup",
        method: "POST",
        body: {
          firstname: data?.firstname,
          lastname: data?.lastname,
          email: data?.email,
          password: data?.password,
        },
      }),
    }),
    signIn: build.mutation({
      query: (data) => ({
        url: "signin",
        method: "POST",
        body: {
          email: data?.email,
          password: data?.password,
        },
      }),
    }),
  }),
});

export const { useCreateAccountMutation, useSignInMutation } = authAPI;
