import { FC } from "react";
import NoiseBg from "../../../assets/png/noisebg.png";
import BadgePurple from "../../../assets/png/Group_13.png";
import YellowPurple from "../../../assets/png/Group_14.png";

interface PriceCardProps {
  name: string;
  price: number;
  properties: string[];
  id?: number;
}

let bgImg =
  "background-url: http://api.thumbr.it/whitenoise-361x400.png?background=ffffff00&noise=626262&density=15&opacity=14";

const PricingCard: FC<PriceCardProps> = ({ name, properties, id, price }) => {
  return (
    <div
      className={`p-6 bg-white relative rounded-xl h-auto md:shadow-md border-[1px] ${
        id == 2 ? " border-orange-300" : "border-gray-200"
      }`}
      style={{
        background: `url(${NoiseBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute flex flex-col items-center justify-center bg-no-repeat h-[130px] w-[100px] top-[-6px] right-4"
        style={{
          background: `url(${
            id == 1 ? BadgePurple : id == 2 ? YellowPurple : BadgePurple
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      >
        <div className="mt-[-30px] flex flex-col items-center justify-center">
          <h4 className="text-[30px] font-bold text-center text-white">
            ${price}
          </h4>
          <div className="text-white font-light text-center">Monthly</div>
        </div>
      </div>
      <h2 className="font-bold text-[23px]  mt-[110px] dark:text-white">
        {name}
      </h2>
      <ul className="list-disc list-inside">
        {properties.map((i) => (
          <li className="font-light dark:text-greyText pl-[-10px] py-2 list-item">
            {" "}
            {i}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PricingCard;
