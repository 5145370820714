import Layout from "../../component/Layout";
import heroImg from "../../assets/png/hero.png";
import FlexibityIcon from "../../assets/png/The conveyer belt, smart industry (1).png";
import PebbleIcon from "../../assets/png/Icosahedron.png";
import EfficiencyIcon from "../../assets/png/Frame 7.png";
import SecureIcon from "../../assets/png/Frame 8.png";
import BlogCard from "../../component/Blog/BlogCard";

const Blog = () => {
  return (
    <Layout>
      {/* Made some comments */}
      <div
        className="h-auto md:h-[80vh] w-screen relative flex items-center justify-center"
        // style={{
        //   backgroundImage: `url(${heroImg})`,
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
      >
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] md:hidden z-50 absolute top-[100px] right-[50px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[430px] left-[270px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 2 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[110px] right-[320px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* Efficiency */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] right-[320px] ">
          <img src={EfficiencyIcon} className=" h-[30px]" />
        </div>
        {/* secure */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] left-[320px] ">
          <img src={SecureIcon} className=" h-[30px]" />
        </div>
        <div className="absolute left-[50%] dark:hidden bg-gradient-to-r from-[#fbdffa] via-[#f8eaf7] to-[#fffef6] blur-3xl translate-x-[-50%] w-[50%] h-[300px] border-[1px] "></div>
        <div className="flex z-20 mt-[60px] flex-col w-[90%] items-center justify-center">
          <div className="flex-col mt-[100px] md:mt-0 items-center justify-start ">
            <div className="w-100 flex items-center justify-start lg:justify-end md:mr-20 md:mt-10">
              <div className="bg-lightPrimary flex w-[fit-content] flex-wrap items-center p-2 rounded-md ">
                <p className=" text-primary text-[14px]">Flexibility</p>
                <div className="h-[20px] w-[20px] flex items-center justify-center">
                  <img src={FlexibityIcon} className="" />
                </div>
              </div>
            </div>
            <h4 className="text-[40px] dark:text-white w-[100%] md:text-[69px] text-center md:text-center lg:w-[800px] font-bold bg-gradient-to-r from-black  to-gray-500 inline-block text-transparent bg-clip-text">
              {/* Rapidly <span>build</span> <br className="hidden lg:hidden" /> */}
              Blog Spot
            </h4>
            <p className="text-center dark:text-greyText md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[20px] text-light font-light">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo, saepe?
            </p>
          </div>
        </div>
      </div>
      {/* 2vp */}
      <div className="h-auto w-screen py-10 mt-20 relative">
        <div className="w-[90%] mx-auto flex flex-col md:items-center md:justify-center items-start justify-start">
          <div className="w-[100%]">
            <h3 className="text-start text-primary text-lg font-bold dark:bg-gradient-to-r dark:from-[#FFA825] dark:to-[#FF00F4] dark:inline-block dark:text-transparent dark:bg-clip-text">
              Latest News
            </h3>
          </div>
          <div className="w-[100%] mx-auto pt-10 grid grid-cols-1  md:grid-cols-3 lg:grid-cols-4 gap-6">
            {Array.from(Array(6)).map((_, i) => (
              <BlogCard key={i} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
