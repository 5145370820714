import React, { FC, CSSProperties } from "react";

interface ButtonProps {
  className?: string;
  styles?: CSSProperties; // Define styles prop type,
  text: string;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({ className, styles, text, ...rest }) => {
  return (
    <div className={className} style={styles} {...rest}>
      {text}
    </div>
  );
};

export default Button;
