import React, { FC } from "react";
import Slider from "react-slick";
import Go from "../../../assets/png/go_logo.svg.png";
import Rust from "../../../assets/png/rust_lang_logo_icon_169776 1.png";
import HTMLogo from "../../../assets/png/hero.png";

const StackSlider: FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  const stackdata = [
    {
      id: 1,
      image: Rust,
    },
    {
      id: 2,
      image: Go,
    },
    {
      id: 3,
      image: HTMLogo,
    },
  ];
  return (
    <div className="w-100 ">
      <div className="slider-container">
        <Slider
          {...settings}
          className="h-[100px] border-[1px] border-green-500"
        >
          {stackdata.map(({ id, image }) => (
            <div
              key={id}
              className="border-[1px] border-red-500 h-[auto] flex items-center justify-center"
            >
              <img src={image} className="h-[40px] w-[50px]" alt="stack logo" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default StackSlider;
