import React, { FC, CSSProperties } from "react";

interface TagLinProps {
  className?: string;
  styles?: CSSProperties; // Define styles prop type,
  text: string;
}

const Tagline: FC<TagLinProps> = ({ className, styles, text, ...rest }) => {
  return (
    <p
      className={`${className} dark:text-greyText text-left md:text-center text-light  w-auto font-light mt-2 text-[19.98px] `}
      style={styles}
      {...rest}
    >
      {text}
    </p>
  );
};

export default Tagline;
