export const links = [
  {
    id: 5,
    name: "Home",
    link: "/home",
  },
  {
    id: 1,
    name: "Pricing",
    link: "/pricing",
  },
  {
    id: 2,
    name: "Blog",
    link: "/blog",
  },
  {
    id: 3,
    name: "About us",
    link: "/aboutus",
  },
];
