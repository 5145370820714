import { FC } from "react";
import SetupImg from "../../../assets/png/ep_set-up.png";
interface BusinessCardProps {}

const BusinessCard: FC<BusinessCardProps> = ({}) => {
  return (
    <div className="bg-white rounded-lg shadow-xl p-4">
      <div className="h-[30px] w-[30px] md:h-[40px] ml-[-5px] mb-2 md:w-[40px]">
        <img src={SetupImg} className="" />
      </div>
      <h3 className="font-bold">Quick to set up</h3>
      <p className="text-light">
        Start developing on your local machine and immediately connect with
        remote
      </p>
    </div>
  );
};

export default BusinessCard;
