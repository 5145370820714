import "./style.css";
import { IoMdClose } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FC, Dispatch, SetStateAction } from "react";
import Applogo from "../../../assets/png/Group 2 (1).png";
import { links } from "../dataLink";
import Button from "../../Button";
interface Props {
  closeModal: Dispatch<SetStateAction<boolean>>;
  showmodal: boolean;
}

const NavModal = ({ closeModal, showmodal }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="modal-nav drop-shadow-lg">
      <div className="modal-container bg-white px-4 pt-4 pb-10">
        <div className="flex items-center mt-4 justify-between">
          <div className="flex items-center justify-center">
            <div className="md:w-[50px] md:mt-4 mt-[10px] md:h-[50px] w-[40px] h-[40px]">
              <img src={Applogo} className="" alt="coral scale logo" />
            </div>
            <h2 className="text-[24px] duration-100 md:text-[30px] pl-1 font-bold m-0 p-0 text-primary">
              Coral Scale
            </h2>
          </div>
          <div className="cursor-pointer" onClick={() => closeModal(false)}>
            <IoMdClose size={28} color={""} />
          </div>
        </div>
        <div className="flex flex-col">
          {links.map(({ id, name, link }) => (
            <NavLink
              key={id}
              to={link}
              className="p-4 m-2 text-primary font-[18px]"
            >
              {name}
            </NavLink>
          ))}
        </div>
        <div className="flex items-center w-[95%] mx-auto">
          <Button
            className="border-[1.5px] mr-4 cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center w-[126.26px] h-[47.86px] text-primary"
            text="Sign in"
            onClick={() => navigate("/signin")}
          />

          <Button
            className="text-white border-[1.5px] cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center w-[126.26px] h-[47.86px] bg-primary"
            text="Sign Up"
            onClick={() => navigate("/signup")}
          />
        </div>
      </div>
    </div>
  );
};

export default NavModal;
