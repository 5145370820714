import Button from "../../Button";

const Enquiries = () => {
  return (
    // sticky top-0
    //
    <div className="p-4 rounded-xl w-[100%] shadow-xl bg-white">
      <h2 className="font-bold text-[30px]">For more enquiries</h2>
      <p className="text-light">
        Fill in the data for profile. It will take a couple of minutes.{" "}
      </p>
      <form className="pt-6">
        {/* Full name */}
        <div className="">
          {/* <div className="text-light">Full name</div> */}
          <input
            placeholder="Your name..."
            className="border-[1px] rounded-md p-2 border-b-gray-300 placeholder:text-[14px] h-[40px] outline-none w-[100%] "
          />
        </div>
        <div className="my-6">
          {/* <div className="text-light">Email</div> */}
          <input
            placeholder="your email address..."
            className="border-[1px] rounded-md p-2 border-b-gray-300 placeholder:text-[14px] h-[40px] outline-none w-[100%]"
          />
        </div>
        <div className="my-6">
          {/* <div className="text-light">Email</div> */}
          <textarea
            placeholder="Enter your message here..."
            className="border-[1px] rounded-md p-2 border-b-gray-300 placeholder:text-[14px] h-[200px] outline-none w-[100%]"
          />
        </div>

        <div className="w-[100%]">
          <Button
            className="w-[100%] px-2 mt-6 border-[1.5px] cursor-pointer border-primary bg-primary rounded-[8.2px] flex items-center justify-center h-[47.86px] text-white"
            text="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default Enquiries;
