import "./App.css";
// import "antd/dist/antd.css";
import { Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SignUp from "./screens/Auth/SignUp";
import SignIn from "./screens/Auth/Signin";
import Pricing from "./screens/Pricing";
import Blog from "./screens/Blog";
import AboutUS from "./screens/AboutUs";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

function App() {
  useEffect(() => {
    AOS.init({
      // once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/signup" element={<SignUp />} />
        <Route index path="/signin" element={<SignIn />} />
        <Route index path="/pricing" element={<Pricing />} />
        <Route index path="/blog" element={<Blog />} />
        <Route index path="/aboutus" element={<AboutUS />} />
        <Route index path="/forgotpassword" element={<ForgotPassword />} />
      </Routes>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
}

export default App;
