import Applogo from "../../../assets/png/Group 2 (1).png";
import Button from "../../../component/Button";
import Navbar from "../../../component/Navbar";
import { BsGithub } from "react-icons/bs";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import AuthBg from "../../../assets/png/authbg.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import { useForm } from "react-hook-form";
import { ICreateAccount } from "../../../config/interface";
import ErrorText from "../../../component/Errors/ErrorText";
import { CustomButton } from "../../../component/CustomButton";
import { useCreateAccountMutation } from "../../../services/auth.service";
import { errorBlock } from "../../../component/controller/errorBlock";
import toast from "react-hot-toast";
const SignUp = ({}) => {
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    reset,
    formState: { errors },
  } = useForm<ICreateAccount>();
  const [createAccount, { isLoading, data, error }] =
    useCreateAccountMutation();

  const onSubmit = async (data: ICreateAccount) => {
    createAccount(data)
      .then((res) => {
        if (res.data) {
          if (res.data?.status == 1) {
            const { token, message } = res.data;
            toast.success(message);
            reset();
            // look for algorithm to has the token on the frontend
            localStorage.setItem("token", token);
            navigate("/");
          }
          return;
        }

        if (res.error) {
          errorBlock(res.error);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <div className="h-screen bg-[#FFFAFA] w-screen grid grid-cols-1 lg:grid-cols-2 relative">
      <div
        className="hidden lg:block h-screen"
        style={{
          background: `url(${AuthBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="flex auth-bg items-center justify-center ">
        <div className="h-auto w-[430px] rounded-md bg-hero-pattern bg-white p-4 shadow-xl">
          <Link
            to={"/"}
            className="mb-2 cursor-pointer flex text-sm underline  text-primary text-center"
          >
            Go to website
          </Link>
          <div className=" flex px-2 items-center justify-start rounded-sm border-[1px] cursor-pointer border-gray-300 h-[40.86px]">
            <div className="bg-black rounded-sm p-[5px]">
              <BsGithub size={20} color="white" />
            </div>
            <p className=" px-2 text-[13px]">Sign up with Github</p>
          </div>
          <div className="flex items-center justify-center mt-4">
            <div className="bg-gray-400 flex items-center justify-center h-[1px] w-[50px]"></div>
            <div className="px-2 text-center text-[13px]">
              Or Sign up with email
            </div>
            <div className="bg-gray-400 flex items-center justify-center h-[1px] w-[50px]"></div>
          </div>
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <label className="text-primary text-[14px] ">First name</label>
              <input
                placeholder="Enter your firstname.."
                type="text"
                {...register("firstname", { required: true })}
                className="border-[1px] placeholder:text-[14px] outline-none p-2 outline:border-[1px] h-[40px] mt-2 border-gray-400 rounded-sm"
              />
              {errors.firstname && <ErrorText />}
            </div>
            <div className="flex flex-col mt-3 ">
              <label className="text-primary text-[14px] ">Last name</label>
              <input
                placeholder="Enter your lastname.."
                type="text"
                {...register("lastname", { required: true })}
                className="border-[1px] placeholder:text-[14px] outline-none p-2 outline:border-[1px] h-[40px] mt-2 border-gray-400 rounded-sm"
              />
              {errors.lastname && <ErrorText />}
            </div>
            <div className="flex flex-col mt-3 ">
              <label className="text-primary text-[14px] ">Email</label>
              <input
                placeholder="Enter your email.."
                type="email"
                {...register("email", { required: true })}
                className="border-[1px] placeholder:text-[14px] outline-none p-2 outline:border-[1px] h-[40px] mt-2 border-gray-400 rounded-sm"
              />
              {errors.email && <ErrorText />}
            </div>
            <div className="flex flex-col mt-3 ">
              <label className="text-primary font-light text-[14px] ">
                Password
              </label>
              <div className="flex w-[100%] items-center border-[1px] overflow-hidden focus:bg-transparent border-gray-400  h-[40px] mt-2  outline:border-[1px] rounded-sm">
                <input
                  placeholder="*****"
                  type={show ? "text" : "password"}
                  {...register("password", { required: true })}
                  className="w-[90%] placeholder:text-[14px] outline-none p-2"
                />

                <div
                  className="cursor-pointer flex w-[10%] items-center justify-center"
                  onClick={() => setshow((prev) => !prev)}
                >
                  {show ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </div>
              </div>
              {errors.password && <ErrorText />}
            </div>
            <div className="flex mt-3">
              <label className=" text-[14px] ">
                <input type="checkbox" required className="" /> By signing up,
                you agree to our{" "}
                <span className="underline text-primary">Terms of Use </span>and
                <span className="underline text-primary"> Privacy Policy.</span>
              </label>
            </div>
            <div className="w-[100%] mt-4">
              <CustomButton
                type="submit"
                loading={isLoading}
                className="w-[100%] px-2 mt-2 border-[1.5px] cursor-pointer border-primary bg-primary rounded flex items-center justify-center h-[40.86px] text-white"
              >
                <p className="">Sign up</p>
              </CustomButton>
            </div>
            <p className="text-center mt-4 text-[14px]">
              Already have an account?{" "}
              <Link to="/signin" className=" font-bold underline ">
                Sign in
              </Link>{" "}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
