import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      state.isLoggedIn = false;
    },
    userLoggedIn: (state, action) => {
      state.isLoggedIn = true;
    },
  },
});

export const { logoutUser, userLoggedIn } = authSlice.actions;

export const authReducerNew = authSlice.reducer;
