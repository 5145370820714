import Layout from "../../component/Layout";
import heroImg from "../../assets/png/hero.png";
import Button from "../../component/Button";
import StackSlider from "../../component/Home/Slider";
import FlexibityIcon from "../../assets/png/The conveyer belt, smart industry (1).png";
import PebbleIcon from "../../assets/png/Icosahedron.png";
import PebbleIconLg from "../../assets/png/Icosahedron 2.png";
import PebbleIconSharp from "../../assets/png/Icosahedron_3sharp.png";
import EfficiencyIcon from "../../assets/png/Frame 7.png";
import SmallEllipse from "../../assets/png/smal ellipse.png";
import SecureIcon from "../../assets/png/Frame 8.png";
import FrontEllipse from "../../assets/png/front_ellipse.png";
import SectionTag from "../../component/SectionTag";
import Step1 from "../../assets/png/Step1.png";
import ConeImg from "../../assets/png/Cone.png";
import SpiralImg from "../../assets/png/Cone (1).png";
import CylinderImg from "../../assets/png/Cylinder 8.png";
import Tagline from "../../component/TagLine";
import PricingCard from "../../component/Pricing/PricingCard";
import { plansPrice } from "../../component/Home/Slider/data";
import BusinessCard from "../../component/Home/BusinessCard";
import SideEllipse from "../../assets/png/front ellipse_1.png";
import Enquiries from "../../component/Home/Enquiries";
import { Switch } from "antd";
import { useState } from "react";

const Pricing = () => {
  const [selected, setselected] = useState("monthly");
  return (
    <Layout>
      {/* 1st vh */}
      <div
        className="h-auto md:h-[80vh] w-screen relative flex items-center justify-center"
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] md:hidden z-50 absolute top-[100px] right-[50px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[430px] left-[270px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 2 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[110px] right-[320px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* Efficiency */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] right-[320px] ">
          <img src={EfficiencyIcon} className=" h-[30px]" />
        </div>
        {/* secure */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] left-[320px] ">
          <img src={SecureIcon} className=" h-[30px]" />
        </div>
        <div className="absolute hidden  left-[50%] bg-gradient-to-r from-[#fbdffa] via-[#f8eaf7] to-[#fffef6] blur-3xl translate-x-[-50%] w-[50%] h-[500px] border-[1px] "></div>
        <div className="flex z-20 mt-[60px] flex-col w-[90%] items-center justify-center">
          <div className="flex-col mt-[100px] md:mt-0 items-center justify-center ">
            <div className="w-100 flex items-center justify-start lg:justify-end md:mr-20 md:mt-10">
              <div className="bg-lightPrimary flex w-[fit-content] flex-wrap items-center p-2 rounded-md ">
                <p className=" text-primary text-[14px]">Flexibility</p>
                <div className="h-[20px] w-[20px] flex items-center justify-center">
                  <img src={FlexibityIcon} className="" />
                </div>
              </div>
            </div>
            <h4 className=" dark:text-white text-[40px] md:text-[69px] text-left md:text-center lg:w-[800px] font-bold bg-gradient-to-r from-black  to-gray-500 inline-block text-transparent bg-clip-text">
              {/* Rapidly <span>build</span> <br className="hidden lg:hidden" /> */}
              Discover our transparent <br className="hidden lg:hidden" />
              <span className=" bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                pricing plans
              </span>
            </h4>
            <p className="text-left md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[20px] text-light font-light">
              Explore our straightforward pricing options.
            </p>
          </div>
        </div>
      </div>
      {/* 2 vp */}
      <div className="h-auto w-screen py-10 relative">
        <div className="w-[90%] mx-auto flex flex-col md:items-center md:justify-center  items-start justify-start">
          <div className="flex justify-center items-center w-[100%] mt-10 ">
            <div className=" w-100 block md:flex items-center md:justify-end p-2">
              <p
                className={` text-[30px] ${
                  selected == "monthly" ? " text-primary" : ""
                }`}
              >
                Monthly
              </p>
            </div>
            <div className=" w-100 block md:flex items-center md:justify-end p-2">
              <Switch
                defaultChecked={false}
                size={"default"}
                onChange={(e) => {
                  if (e) {
                    setselected("annually");
                  } else {
                    setselected("monthly");
                  }
                }}
              />
            </div>
            <div className=" block md:flex items-center md:justify-start p-2">
              <p
                className={`text-[30px] ${
                  selected == "annually" ? " text-primary" : ""
                }`}
              >
                Annually
              </p>
            </div>
          </div>
          <div className="md:w-[85%] w-[100%] mx-auto md:px-6 pt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {plansPrice.map(({ id, price, name, properties }, i) => (
              <PricingCard
                key={id}
                price={price}
                name={name}
                properties={properties}
                id={id}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
