import React, { FC, CSSProperties } from "react";

interface ButtonProps {
  className?: string;
  styles?: CSSProperties; // Define styles prop type,
  text: string;
}

const SectionTag: FC<ButtonProps> = ({ className, styles, text, ...rest }) => {
  return (
    <p
      className={`${className} w-[fit-content] p-2 rounded-lg bg-lightPrimary text-yellow text-center text-[13px]`}
      style={styles}
      {...rest}
    >
      {text}
    </p>
  );
};

export default SectionTag;
