export const plansPrice = [
  {
    id: 1,
    name: "Starter",
    price: 0,
    properties: [
      "Automatic CI/CD (Git Integration)",
      "Support for multiple stacks",
      "Community support",
      "Analytics",
      "1 custom domain (includes free SSL)",
      "Market place access",
      "1 volume per pebble",
    ],
  },
  {
    id: 2,
    name: "Basic (Popular)",
    price: 20,
    properties: [
      "Includes all starter features",
      "1 core",
      "1GB RAM",
      "Dedicated build pipeline (Faster builds)",
      "Weekly backups",
      "Script Scheduler",
      "Customer support",
      "Unlimited custom domain (includes free SSL)",
      "Multiple volumes per pebble",
    ],
  },
  {
    id: 3,
    name: "Pro",
    price: 32,
    properties: [
      "Includes all starter features",
      "2 cores",
      "2GB RAM",
      "Dedicated build pipeline (Faster builds)",
      "Weekly backups",
      "Script Scheduler",
      "Customer support",
      "Unlimited custom domain (includes free SSL)",
      "Multiple volumes per pebble",
    ],
  },
];
