import { combineReducers } from "redux";
import { authReducerNew } from "../slice/authSlice";
import { authAPI } from "../../services/auth.service";

export const rootReducer = combineReducers({
  auth: authReducerNew,
  [authAPI.reducerPath]: authAPI.reducer,
  //   projectSite: projectSiteReducer,
  //   userDetails: userDetailsReducer,
  //   layout: layoutReducer,
  //   domain: domainReducer,

  //   [DCUAPI.reducerPath]: DCUAPI.reducer,
  //   [meterAPI.reducerPath]: meterAPI.reducer,
  //   [customerClassAPI.reducerPath]: customerClassAPI.reducer,
  //   [customerAPI.reducerPath]: customerAPI.reducer,
  //   [revenueAPI.reducerPath]: revenueAPI.reducer,
  //   [salesanalyticsAPI.reducerPath]: salesanalyticsAPI.reducer,
  //   [rolesAPI.reducerPath]: rolesAPI.reducer,
  //   [projectSiteAPI.reducerPath]: projectSiteAPI.reducer,
  //   [siteMapAPI.reducerPath]: siteMapAPI.reducer,
});
