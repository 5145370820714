import Applogo from "../../../assets/png/Group 2 (1).png";
import Button from "../../../component/Button";
import Navbar from "../../../component/Navbar";
import { BsGithub } from "react-icons/bs";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import AuthBg from "../../../assets/png/authbg.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ISignIn } from "../../../config/interface";
import { useForm } from "react-hook-form";
import { useSignInMutation } from "../../../services/auth.service";
import toast from "react-hot-toast";
import { errorBlock } from "../../../component/controller/errorBlock";
import { CustomButton } from "../../../component/CustomButton";
import ErrorText from "../../../component/Errors/ErrorText";

const Signin = ({}) => {
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    reset,
    formState: { errors },
  } = useForm<ISignIn>();
  const [signIn, { isLoading, data, error }] = useSignInMutation();

  const onSubmit = async (data: ISignIn) => {
    signIn(data)
      .then((res) => {
        console.log(data);
        if (res.data) {
          if (res.data?.status == 1) {
            const { token, message } = res.data;
            toast.success(message);
            reset();
            localStorage.setItem("token", token);
            navigate("/");
          }
          return;
        }

        if (res.error) {
          errorBlock(res.error);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="h-screen bg-[#FFFAFA] w-screen grid grid-cols-1 lg:grid-cols-2 relative">
      <div
        className="hidden lg:block h-screen"
        style={{
          background: `url(${AuthBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="flex auth-bg items-center justify-center ">
        <div className="h-auto w-[430px] rounded-md bg-white p-6 shadow-xl">
          <Link
            to={"/"}
            className="mb-2 cursor-pointer flex text-sm underline  text-primary text-center"
          >
            Go to website
          </Link>
          <div
            onClick={() => {
              window.location.href =
                "https://github.com/login/oauth/select_account?client_id=22e850a6ac69e54e5b76";
            }}
            className=" flex px-2 items-center justify-start rounded-sm border-[1px] cursor-pointer border-gray-300 h-[40.86px]"
          >
            <div className="bg-black rounded-sm p-[5px]">
              <BsGithub size={20} color="white" />
            </div>
            <p className=" px-2 text-[13px]">Sign in with Github</p>
          </div>
          <div className="flex items-center justify-center my-4">
            <div className="bg-gray-400 flex items-center justify-center h-[1px] w-[50px]"></div>
            <div className="px-2 text-center text-[13px]">
              Or Sign in with your email
            </div>
            <div className="bg-gray-400 flex items-center justify-center h-[1px] w-[50px]"></div>
          </div>

          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col mt-3 ">
              <label className="text-primary text-[14px] ">Email</label>
              <input
                placeholder="Enter your email.."
                type="text"
                {...register("email", { required: true })}
                className="border-[1px] placeholder:text-[14px] outline-none p-2 outline:border-[1px] h-[40px] mt-2 border-gray-400 rounded-sm"
              />
              {errors.email && <ErrorText />}
            </div>
            <div className="flex flex-col mt-3 ">
              <label className="text-primary font-light text-[14px] ">
                Password
              </label>
              <div className="flex w-[100%] items-center border-[1px] overflow-hidden focus:bg-transparent border-gray-400  h-[40px] mt-2  outline:border-[1px] rounded-sm">
                <input
                  placeholder="*****"
                  type={show ? "text" : "password"}
                  {...register("password", { required: true })}
                  className="w-[90%] placeholder:text-[14px] outline-none p-2"
                />

                <div
                  className="cursor-pointer flex w-[10%] items-center justify-center"
                  onClick={() => setshow((prev) => !prev)}
                >
                  {show ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </div>
              </div>
              {errors.password && <ErrorText />}
            </div>
            <div className="flex mt-3">
              <label className=" text-[14px] ">
                <input type="checkbox" required className="" /> By signing up,
                you agree to our{" "}
                <span className="underline text-primary">Terms of Use </span>and
                <span className="underline text-primary"> Privacy Policy.</span>
              </label>
            </div>
            <div className="w-[100%] mt-4">
              <CustomButton
                type="submit"
                loading={isLoading}
                className="w-[100%] px-2 mt-2 border-[1.5px] cursor-pointer border-primary bg-primary rounded flex items-center justify-center h-[40.86px] text-white"
              >
                <p className="">Sign up</p>
              </CustomButton>
            </div>
            <p className="text-center mt-4 text-[14px]">
              Don't have an account?{" "}
              <Link to="/signup" className=" font-bold underline ">
                Sign up
              </Link>{" "}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;

//</div>  <div className="absolute hidden w-[100%] items-center justify-center">
//    <div className="flex items-center justify-center">
//      <div className="md:w-[50px] md:mt-4 mt-[10px] md:h-[50px] w-[35px] h-[35px]">
//        <img src={Applogo} className="" alt="coral scale logo" />
//      </div>
//      <h2 className="text-[24px] duration-100 md:text-[24px] pl-1 font-bold m-0 p-0 text-primary">
//        Coral Scale
//      </h2>
//    </div>{" "}
//  </div>;
