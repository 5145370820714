import moment from "moment";

const BlogCard = () => {
  return (
    <div className="border-[1px] rounded-md w-[100%] dark:border-transparent dark:bg-lightblack bg-white h-auto p-2">
      <div className="h-[auto] overflow-hidden rounded-md w-[100%]">
        <img
          className=""
          src={
            "https://images.unsplash.com/photo-1506744038136-46273834b3fb?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
        />
      </div>
      <div className="mt-4">
        <h3 className="font-bold dark:text-white">
          The Impact of Music to the Workplace: How Technology is Changing
        </h3>
        <p className="text-light dark:text-greyText">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
          officia ea maiores...
        </p>
        <div className="flex items-center mt-4 justify-between">
          <div className="flex items-center ">
            <div className="h-[25px] mr-2 rounded-full w-[25px]  overflow-hidden">
              <img
                className="w-[100%] h-[100%] "
                src={
                  "https://images.unsplash.com/photo-1554151228-14d9def656e4?q=80&w=3086&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
              />
            </div>
            <div className="text-[14px] dark:text-white">James Allen</div>
          </div>
          <div className="text-[14px] dark:text-white">
            {moment(Date.now()).format("ll")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
