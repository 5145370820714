export const footerdata = [
  {
    name: "Product",
    links: [
      { id: 1, text: "Sitemap", link: "" },
      { id: 2, text: "Pricing", link: "/pricing" },
    ],
  },
  {
    name: "Developers",
    links: [
      { id: 1, text: "Community", link: "/" },
      { id: 2, text: "Discord server", link: "/" },
      { id: 3, text: "Help center", link: "/" },
    ],
  },
  {
    name: "Company",
    links: [
      { id: 1, text: "About", link: "/aboutus" },
      { id: 2, text: "Contact", link: "/contactus" },
      { id: 3, text: "Blog", link: "/blog" },
      { id: 4, text: "Careers", link: "/" },
    ],
  },
  {
    name: "Social",
    links: [
      { id: 1, text: "Youtube", link: "https://www.youtube.com/@loftycodes" },
      { id: 2, text: "LinkedIn", link: "/" },
      { id: 3, text: "Twitter", link: "https://x.com/coralscale" },
    ],
  },
];
