import { CSSProperties, ReactNode } from "react";
import { css } from "@emotion/react";
import { MoonLoader, PulseLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

interface CustomButtonProps {
  className?: string;
  onClick?: () => void;
  styles?: CSSProperties;
  children: ReactNode;
  color?: string;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  [key: string]: any; // Allows additional props to be passed
}

export const CustomButton = ({
  className,
  onClick,
  styles,
  children,
  color,
  loading,
  type = "button", // Default type to "button"
  ...rest
}: CustomButtonProps) => {
  return (
    <button
      type={type}
      className={className}
      style={styles}
      {...rest}
      onClick={onClick}
    >
      {loading && (
        <PulseLoader
          cssOverride={override}
          color={color ?? "white"}
          size={5}
          speedMultiplier={1}
        />
      )}
      {loading ? "" : children}
    </button>
  );
};
