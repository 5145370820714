interface Props {
  text?: string;
}

const ErrorText = ({ text }: Props) => {
  return (
    <div className="text-red-500 text-[10px] font-light py-1">
      {text ?? "Fields is required"}
    </div>
  );
};

export default ErrorText;
