import { toast } from "react-hot-toast";

export const errorBlock = (err: any) => {
  if (err?.response) {
    return toast.error(err.response?.data?.response);
  } else {
    if (err?.data) {
      return toast.error(err.data?.message);
    } else {
      toast.error(err.message);
    }
  }
};
