import Applogo from "../../../assets/png/Group 2 (1).png";
import Button from "../../../component/Button";
import Navbar from "../../../component/Navbar";
import { BsGithub } from "react-icons/bs";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import AuthBg from "../../../assets/png/authbg.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = ({}) => {
  const [show, setshow] = useState(false);
  return (
    <div className="h-screen bg-[#FFFAFA] w-screen grid grid-cols-1 lg:grid-cols-2 relative">
      <div
        className="hidden lg:block h-screen"
        style={{
          background: `url(${AuthBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="flex auth-bg items-center justify-center ">
        <div className="h-auto w-[430px] rounded-md bg-white p-6 shadow-xl">
          <Link
            to={"/signin"}
            className="mb-2 cursor-pointer flex text-sm underline  text-primary text-center"
          >
            Back to Sigin
          </Link>

          <div className="">
            <h2 className="font-bold text-primary text-[20px]">
              Recover password
            </h2>
            <p className="text-light text-[14px] text-left">
              Enter the email associated with your account and we will send an
              email with instructions to recover your password
            </p>
          </div>
          <form className="mt-2">
            <div className="flex flex-col mt-4 ">
              <label className="text-primary text-[14px] ">Email</label>
              <input
                placeholder="Enter your email.."
                type="email"
                required
                className="border-[1px] placeholder:text-[14px] outline-none p-2 outline:border-[1px] h-[40px] mt-2 border-gray-400 rounded-sm"
              />
            </div>

            <div className=" mt-4 hidden">
              <label className=" text-[14px] ">
                <input type="checkbox" required className="" /> By signing up,
                you agree to our{" "}
                <span className="underline text-primary">Terms of Use </span>and
                <span className="underline text-primary"> Privacy Policy.</span>
              </label>
            </div>
            <div className="w-[100%]  mt-6">
              <Button
                className="w-[100%] px-2 mt-4 border-[1.5px] cursor-pointer border-primary bg-primary rounded-[8.2px] flex items-center justify-center h-[40.86px] text-white"
                text="Send Instructions"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

//</div>  <div className="absolute hidden w-[100%] items-center justify-center">
//    <div className="flex items-center justify-center">
//      <div className="md:w-[50px] md:mt-4 mt-[10px] md:h-[50px] w-[35px] h-[35px]">
//        <img src={Applogo} className="" alt="coral scale logo" />
//      </div>
//      <h2 className="text-[24px] duration-100 md:text-[24px] pl-1 font-bold m-0 p-0 text-primary">
//        Coral Scale
//      </h2>
//    </div>{" "}
//  </div>;
