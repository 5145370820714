import { Link, useNavigate } from "react-router-dom";
import Applogo from "../../assets/png/Group 2 (1).png";
import { footerdata } from "./footerdata";
import { useState, useEffect } from "react";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { GrPersonalComputer } from "react-icons/gr";
const Footer = () => {
  const navigate = useNavigate();
  const [showmodal, setshowmodal] = useState(false);
  const optionsNew = [
    {
      id: 1,
      text: "light",
    },
    {
      id: 2,
      text: "dark",
    },
    {
      id: 3,
      text: "system",
    },
  ];
  const options = ["light", "dark", "system"];
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );
  const element = document.documentElement;
  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");

  // console.log(darkQuery, "dark query");
  function onWindowMatch() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }

  onWindowMatch();
  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;
      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  }, [theme]);
  return (
    <div className="w-screen md:h-[70vh] h-auto py-10 mt-40 flex items-center dark:bg-lightblack bg-lightGrey justify-center">
      <div className="w-[90%] mx-auto md:mt-[-180px] grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="col-span-1 ">
          <div className="flex flex-col items-start justify-start md:pr-20">
            <div className=" flex items-center">
              <div className="md:w-[50px] md:mt-4 mt-[10px] md:h-[50px] w-[40px] h-[40px]">
                <img src={Applogo} className="" alt="coral scale logo" />
              </div>
              <h2 className="dark:text-white text-[24px] duration-100 md:text-[30px] pl-1 font-bold m-0 p-0 text-primary">
                Coral Scale
              </h2>
            </div>
            <div className="dark:text-white text-light mt-[-4px]">
              Building a great product and a great company means building a team
              of talented people that can work well together and like to be
              challenged.{" "}
            </div>
            <div className="w-[100%] mt-3 flex items-center justify-start">
              <div className=" w-fit p-[1px] rounded-full dark:bg-black bg-white dark:border-0 inset-6 justify-end items-center flex">
                {optionsNew.map(({ id, text }) => (
                  <div
                    key={id}
                    onClick={() => setTheme(text)}
                    className={`p-2 cursor-pointer m-1 rounded-full ${
                      theme === text ? "bg-primary shadow-sm " : "white"
                    }`}
                  >
                    {text == "light" ? (
                      <MdOutlineLightMode
                        color={`${theme == text ? "white" : "#702C6D"}`}
                        size={14}
                      />
                    ) : text == "dark" ? (
                      <MdDarkMode
                        color={`${theme == text ? "white" : "#702C6D"}`}
                        size={14}
                      />
                    ) : (
                      <GrPersonalComputer
                        color={`${theme == text ? "white" : "#702C6D"}`}
                        size={14}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 pt-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {footerdata.map(({ name, links }) => (
              <div className="" key={name}>
                <h3 className="dark:text-white font-bold">{name}</h3>
                <ul className="list-none line-inside">
                  {links.map(({ id, link, text }) => (
                    <li
                      key={id}
                      className="text-light py-2  dark:text-greyText"
                    >
                      <Link
                        to={link}
                        target={`${name == "Social" ? "_blank" : "_parent"}`}
                      >
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
