import Layout from "../../component/Layout";
import heroImg from "../../assets/png/hero.png";
import Button from "../../component/Button";
import StackSlider from "../../component/Home/Slider";
import FlexibityIcon from "../../assets/png/The conveyer belt, smart industry (1).png";
import PebbleIcon from "../../assets/png/Icosahedron.png";
import PebbleIconLg from "../../assets/png/Icosahedron 2.png";
import PebbleIconSharp from "../../assets/png/Icosahedron_3sharp.png";
import EfficiencyIcon from "../../assets/png/Frame 7.png";
import SmallEllipse from "../../assets/png/smal ellipse.png";
import SecureIcon from "../../assets/png/Frame 8.png";
import FrontEllipse from "../../assets/png/front_ellipse.png";
import "./style.css";
import SectionTag from "../../component/SectionTag";
import Step1 from "../../assets/png/Step1.png";
import ConeImg from "../../assets/png/Cone.png";
import SpiralImg from "../../assets/png/Cone (1).png";
import CylinderImg from "../../assets/png/Cylinder 8.png";
import SafeImg from "../../assets/png/safe.png";
import Tagline from "../../component/TagLine";
import PricingCard from "../../component/Pricing/PricingCard";
import { plansPrice } from "../../component/Home/Slider/data";
import BusinessCard from "../../component/Home/BusinessCard";
import SideEllipse from "../../assets/png/front ellipse_1.png";
import Enquiries from "../../component/Home/Enquiries";
import TextTransition, { presets } from "react-text-transition";
import { useState, useEffect } from "react";

const Home = () => {
  const TEXTS = ["build", "scale"];
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <Layout>
      {/* 1st vh */}
      <div
        className="h-screen w-screen relative flex items-center justify-center"
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] md:hidden z-50 absolute top-[100px] right-[50px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 1 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[430px] left-[270px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* pebble 2 */}
        <div className="w-[100px] h-[100px] hidden lg:flex z-50 absolute top-[110px] right-[320px] ">
          <img src={PebbleIcon} className="" />
        </div>
        {/* Efficiency */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] right-[320px] ">
          <img src={EfficiencyIcon} className=" h-[30px]" />
        </div>
        {/* secure */}
        <div className="hidden h-[60px] lg:block z-50 absolute top-[330px] left-[320px] ">
          <img src={SecureIcon} className=" h-[30px]" />
        </div>
        <div className="absolute flex dark:hidden left-[50%] bg-gradient-to-r from-[#fbdffa] via-[#f8eaf7] to-[#fffef6] blur-3xl translate-x-[-50%] w-[50%] h-[500px] border-[1px] "></div>
        <div className="flex z-20 flex-col w-[90%] items-center justify-center">
          <div className="flex-col mt-[100px] md:mt-0 items-center justify-center ">
            <div className="w-100 flex items-center justify-start lg:justify-end md:mr-20 md:mt-10">
              <div className="bg-lightPrimary flex w-[fit-content] flex-wrap items-center p-2 rounded-md ">
                <p className="dark:text-white text-primary text-[14px]">
                  Flexibility
                </p>
                <div className="h-[20px] w-[20px] flex items-center justify-center">
                  <img src={FlexibityIcon} className="" />
                </div>
              </div>
            </div>
            <h4 className="dark:text-white text-[40px] lg:text-[69px] text-left md:text-center lg:w-[800px] font-bold bg-gradient-to-r from-black  to-gray-500 inline-block text-transparent bg-clip-text">
              Rapidly{" "}
              <span className="pr-2">
                <TextTransition
                  className=" pr-2 text-yellow inline w-auto"
                  style={{}}
                  springConfig={presets.default}
                  inline
                  direction="up"
                >
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </span>
              {/* <span className="bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                build
              </span>{" "} */}
              <br className="hidden lg:hidden" />
              and deploy your applications
            </h4>
            <p className=" dark:text-white text-left md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[20px] text-light font-light">
              Emphasizes quick iteration, continuous testing, and efficient
              deployment <br /> to deliver high-quality applications to users
              faster.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center w-[100%] mt-10 ">
            <div className=" w-100 block md:flex items-center md:justify-end p-2">
              <Button
                className=" dark:border-white dark:text-white md:w-[199px] border-[1.5px] cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center h-[47.86px] text-primary"
                text="How it works"
              />
            </div>{" "}
            <div className=" block md:flex items-center md:justify-start p-2">
              <Button
                className="  dark:border-primary md:w-[199px] border-[1.5px] cursor-pointer border-primary bg-primary rounded-[8.2px] flex items-center justify-center h-[47.86px] text-white"
                text="Deploy new project"
              />
            </div>
          </div>
          <div className="mt-16">
            <h3 className="font-bold  text-center dark:bg-gradient-to-r dark:from-[#FFA825] dark:to-[#FF00F4] dark:inline-block dark:text-transparent dark:bg-clip-text">
              Supported Stacks
            </h3>
            <div className="w-[800px] mt-10 hidden">
              <StackSlider />
            </div>
          </div>
        </div>
      </div>

      {/* 2 Vp */}
      <div className="h-screen md:overflow-hidden w-screen relative flex items-center justify-center">
        {/* FrontEllipse */}
        <div className="hidden md:flex w-[100px] h-[100px] blur-sm left-[50%] bottom-[200px] lg:flex absolute ">
          <img src={FrontEllipse} className="" />
        </div>
        {/* Ellipse */}
        {/* -z-20 */}
        <div className="w-[200px] h-[300px] md:w-[479px] md:h-[487px] lg:w-[200px] blur-sm left-0 lg:flex absolute ">
          <img src={SmallEllipse} className="" />
        </div>
        {/* big peddle */}
        {/* -z-20 */}
        <div className="w-[100px] h-[100px] lg:w-[200px] blur-sm right-[80px] lg:h-[200px] lg:flex  absolute top-[100px] lg:right-[270px] ">
          <img src={PebbleIconLg} className="" />
        </div>
        {/*  */}
        <div className=" dark:bg-white wrapper before:hidden md:before:block md:bg-white w-[90%] md:w-[777px] md:h-[417px] md:shadow-xl md:p-10 md:rounded-[10px] ">
          {/* absolute */}
          <div className="absolute hidden md:block"></div>
          <div className="relative">
            <SectionTag text={"Simplified Deployment"} />
            <h2 className="  font-bold mt-2 dark:text-black text-primary text-[40px] md:text-[44.9px]">
              Easily{" "}
              <span className="bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                deploy and scale
              </span>{" "}
              your applications to users in minutes
            </h2>
            <p className=" dark:text-black text-light w-auto font-light mt-2 text-[19.98px]">
              Developing a great product is a lot of work already. Deploying
              your product should not be an hasty.
            </p>
            <Button
              className="text-white mt-6 border-[1.5px] cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center w-[180.26px] h-[47.86px] bg-primary"
              text="Create Account"
            />
          </div>
        </div>
      </div>

      {/* 3 Vp */}
      <div className="w-100 w-[90%] grid grid-cols-1 md:place-items-center mx-auto">
        <div className="flex flex-col md:flex md:flex-row items-start justify-start md:items-start md:justify-start">
          <h2 className="text-[40px] py-2 pr-4 text-left md:text-[44px] font-bold  pt-4  bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
            Easy,{" "}
            <span className="text-black dark:text-white">
              Secure, and <br className="block md:hidden" /> Flexible
            </span>
          </h2>
        </div>

        <Tagline
          text="Shipping your application to customers can be difficult but we got you
          covered."
        />
      </div>

      {/* 4vp */}
      <div className="h-auto w-screen py-10 mt-20 bg-lightGrey dark:bg-lightblack relative">
        <div className="w-[90%] relative  lg:w-[60%] md:w-[70%] mx-auto flex flex-col md:flex md:flex-col items-start justify-start md:items-center md:justify-center">
          <SectionTag text={"Productivity"} />

          <h2 className="font-bold dark:text-white md:text-center mt-2 text-[40px] md:text-[44.9px]">
            Built with{" "}
            <span className=" bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
              Developers
            </span>{" "}
            in Mind
          </h2>
          <p className=" dark:text-greyText md:text-center text-light w-auto font-light mt-2 text-[19.98px]">
            Focus on building great products while we do the heavy lifting by
            providing a platform for you to easily push putdates to your users
            in seconds. Develop your application in any language of choice and
            leave the rest to us.
          </p>
          <div className="relative w-[100%]">
            <div className="w-[80px] h-[80px] hidden lg:w-[100px] -right-10 lg:h-[100px] lg:flex absolute md:top-[200px] lg:right-0 ">
              <img src={ConeImg} className="" />
            </div>

            {/* step 1 */}
            <div
              data-aos="fade-right"
              className="w-[100%] mt-6 relative md:py-20 py-10  flex items-center z-50 justify-start"
            >
              <div className="w-[100px] -top-6 h-[100px] blur-sm lg:w-[100px] right-[80px] lg:h-[100px] lg:flex absolute md:top-10  lg:right-[230px] ">
                <img src={PebbleIconSharp} className="" />
              </div>
              <div className="md:w-[603px] rounded-lg z-50 text-[16px] bg-white p-6 shadow-lg text-left text-light font-light mt-2 md:text-[19.98px]">
                The intuitive UI makes setting up CoralScale easy and onboarding
                is quick thanks to out-of-the-box support for the most popular
                tools like fastlane. We offer free onboarding assistance to help
                you get all set.
              </div>
            </div>
            {/* step 2 */}
            <div
              data-aos="fade-left"
              className="w-[100%] md:py-20 py-10  flex items-center relative justify-end"
            >
              <div className="w-[100px] h-[100px]  blur-sm lg:w-[100px] right-[80px] lg:h-[100px] lg:flex absolute top-10 lg:right-[550px] ">
                <img src={PebbleIconSharp} className="" />
              </div>
              <div className="w-[80px] h-[80px] hidden lg:w-[120px] -left-[100px] lg:h-[120px] lg:flex absolute md:top-[150px] lg:right-0 ">
                <img src={SpiralImg} className="" />
              </div>
              <div className="md:w-[603px] rounded-lg z-50 text-[16px] bg-white p-6 shadow-lg text-left text-light font-light mt-2 md:text-[19.98px]">
                The intuitive UI makes setting up CoralScale easy and onboarding
                is quick thanks to out-of-the-box support for the most popular
                tools like fastlane. We offer free onboarding assistance to help
                you get all set.
              </div>
            </div>
            {/* step 3 */}
            <div
              data-aos="fade-right"
              className="w-[100%] md:py-20 py-10  relative flex items-center justify-start"
            >
              <div className="w-[100px] -top-4 h-[100px]  blur-sm lg:w-[100px] md:right-[80px] lg:h-[100px] lg:flex absolute md:top-10  lg:right-[230px] ">
                <img src={PebbleIconSharp} className="" />
              </div>
              <div className="w-[80px] h-[80px] hidden lg:w-[120px] -right-[100px] lg:h-[120px] lg:flex absolute md:top-[150px] lg:right-0 ">
                <img src={CylinderImg} className="" />
              </div>
              <div className="md:w-[603px] z-50 rounded-lg bg-white p-6 shadow-lg text-left text-light font-light mt-2 md:text-[19.98px]">
                The intuitive UI makes setting up CoralScale easy and onboarding
                is quick thanks to out-of-the-box support for the most popular
                tools like fastlane. We offer free onboarding assistance to help
                you get all set.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 5vp */}
      <div className="h-auto w-screen py-10 mt-20 relative">
        <div className="w-[90%] mx-auto flex flex-col md:items-center md:justify-center  items-start justify-start">
          <div className="w-[100%] flex md:items-center md:justify-center">
            <SectionTag text="Subscription plans" />
          </div>
          <h2 className="text-[40px] dark:text-white text-start md:text-center py-2 pr-4 md:text-[44px] font-bold  pt-4 ">
            A{" "}
            <span className="bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
              subscription
            </span>{" "}
            level for everyone
          </h2>
          <p className="text-light dark:text-greyText text-left md:text-center md:w-[50%] w-auto font-light mt-2 text-[19.98px]">
            Choose the subscription level that supports your development
            velocity and start building with Docker Desktop today.
          </p>

          <div className=" md:w-[90%] gap-10 w-[85%] mx-auto md:px-6 pt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8">
            {plansPrice.map(({ id, price, name, properties }, i) => (
              <PricingCard
                key={id}
                price={price}
                name={name}
                properties={properties}
                id={id}
              />
            ))}
          </div>
          <div className="flex w-[100%] items-center justify-center">
            <Button
              className="md:w-[210px] px-2 mt-12 border-[1.5px] cursor-pointer border-primary bg-primary rounded-[8.2px] flex items-center justify-center h-[47.86px] text-white"
              text="View Subscriptions"
            />
          </div>
        </div>
      </div>

      {/* 6vp */}
      <div className="lg:h-screen md:h-auto h-auto py-10 mt-10 border-y-[1px] border-gray-200 w-screen relative ">
        {/* md:w-[479px] lg:h-[487px] lg:w-[300px] blur-sm left-0 lg:flex -z-20
        absolute */}
        <div className="flex top-[300px] md:top-0 lg:block absolute lg:w-[500px] -z-20">
          <img src={SideEllipse} className="" />
        </div>
        {/* <hr className="mt-10" /> */}
        <div className="w-[90%] mx-auto flex flex-col md:items-center md:justify-center items-start justify-start">
          <SectionTag text={"Why Coral scale"} />
          <h2 className="dark:text-white text-[40px] text-start md:text-center py-2 pr-4 md:text-[44px] font-bold">
            Built for{" "}
            <span className="bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
              Developer and Business
            </span>{" "}
            Velocity
          </h2>
          <div className="md:w-[50%] mx-auto">
            <Tagline text="Developing a great product is a lot of work already. Deploying your product should not be an hasty." />
          </div>
          <div className="grid place-content-center gap-8 mt-10 grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
            {Array.from(Array(5)).map((_, i) => (
              <BusinessCard key={i} />
            ))}
          </div>
        </div>
      </div>

      {/* 7 vp */}
      <div className="h-auto py-10 w-screen relative overflow-hidden">
        <div className="w-[90%] justify-items-center place-items-center mx-auto  grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:gap-20 lg:gap-80">
          <div className="p-8 rounded-xl w-[100%]">
            <div className="w-[200px] mb-8 h-[200px]">
              <img src={SafeImg} className="" />
            </div>
            <div className="">
              <h2 className="dark:text-white text-[30px] md:text-center lg:text-start font-bold ">
                Secure your application
              </h2>
              <p className="dark:text-greyText text-light md:text-center lg:text-start text-left w-auto font-light mt-2 text-[19.98px]">
                Choose the subscription level that supports your development
                velocity and start building with Docker Desktop today.
              </p>
              <div className="flex w-[100%] items-center justify-start md:justify-center lg:justify-start">
                <Button
                  className="w-[100%] md:w-[210px] px-2 mt-4 border-[1.5px] cursor-pointer border-primary bg-primary rounded-[8.2px] flex items-center justify-center h-[47.86px] text-white"
                  text="Join"
                />
              </div>
            </div>
          </div>
          <div className="">
            <Enquiries />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
