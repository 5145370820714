import Layout from "../../component/Layout";
import heroImg from "../../assets/png/hero.png";
import Button from "../../component/Button";
import StackSlider from "../../component/Home/Slider";
import FlexibityIcon from "../../assets/png/The conveyer belt, smart industry (1).png";
import PebbleIcon from "../../assets/png/Icosahedron.png";
import PebbleIconLg from "../../assets/png/Icosahedron 2.png";
import PebbleIconSharp from "../../assets/png/Icosahedron_3sharp.png";
import EfficiencyIcon from "../../assets/png/Frame 7.png";
import SmallEllipse from "../../assets/png/smal ellipse.png";
import SecureIcon from "../../assets/png/Frame 8.png";
import FrontEllipse from "../../assets/png/front_ellipse.png";
import SectionTag from "../../component/SectionTag";
import Step1 from "../../assets/png/Step1.png";
import ConeImg from "../../assets/png/Cone.png";
import SpiralImg from "../../assets/png/Cone (1).png";
import CylinderImg from "../../assets/png/Cylinder 8.png";
import Tagline from "../../component/TagLine";
import PricingCard from "../../component/Pricing/PricingCard";
import { plansPrice } from "../../component/Home/Slider/data";
import BusinessCard from "../../component/Home/BusinessCard";
import SideEllipse from "../../assets/png/front ellipse_1.png";
import Enquiries from "../../component/Home/Enquiries";

const AboutUS = () => {
  return (
    <Layout>
      {/* 1st vh */}
      <div
        className="md:h-screen w-screen relative flex items-center justify-center"
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex z-20 flex-col w-[90%] mt-[160px] lg:mt-[100px]  mx-auto items-center justify-center">
          <div className="flex-col md:mt-0 items-center justify-center ">
            <div className="w-[100%] flex  items-center  justify-start md:justify-center">
              <h4 className=" dark:text-white text-[40px] lg:mt-0 w-[100%] md:w-[100%] md:text-[69px] mx-auto text-left md:text-center font-bold bg-gradient-to-r from-black  to-gray-500 inline-block text-transparent bg-clip-text">
                We are Coralscale
              </h4>
            </div>
            <p className="dark:text-white text-left md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[32px] text-light font-light">
              The team behind the magic
            </p>{" "}
            <p className="dark:text-greyText text-left lg:w-[50%] mx-auto md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[17px] text-light font-light">
              Building a great product and a great company means building a team
              of talented people that can work well together and like to be
              challenged. Talent is a necessity to succeed, but if you prefer to
              work on your own, keep your head down and don’t take criticism
              well, then you will not be happy here. If the opposite is true,
              you like to work in a team, take initiative and stay humble, then
              come say hi!
            </p>
          </div>
          <div className="h-[1.33px] mt-6 w-[100%] lg:w-[50%] mx-auto bg-gradient-to-r from-[#FFA825] to-[#FF00F4]"></div>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-3 items-center lg:w-[50%] mt-10 ">
            <div className=" col-span-2">
              <h3 className="dark:text-white text-[29px] font-bold">
                Our vision is to bring continuous improvement to software
                development
              </h3>
              <p className="dark:text-greyText  text-left md:text-left pt-4 lg:pt-0 lg:text-left m-0 p-0 text-[17px] text-light font-light">
                Our mission is to give developers actionable feedback as fast as
                possible by separating signal from noise.
              </p>
            </div>
            <div className="flex lg:flex-col items-center justify-between ">
              <div className="flex flex-col justify-center items-center">
                <h2 className="font-bold text-[29px] bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                  10y+
                </h2>
                <p className="text-left dark:text-greyText  md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[17px] text-light font-light">
                  expertise in the field
                </p>
              </div>
              <div className="flex md:mt-0 lg:mt-6 flex-col justify-center items-center">
                <h2 className="font-bold text-[29px] bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                  7M+
                </h2>
                <p className=" dark:text-greyText text-left md:text-center pt-4 lg:pt-0 lg:text-center m-0 p-0 text-[17px] text-light font-light">
                  successful builds
                </p>
              </div>
            </div>
          </div>
          <div className="h-[1.33px] mt-6 w-[100%] lg:w-[50%] mx-auto bg-gradient-to-r from-[#FFA825] to-[#FF00F4]"></div>
        </div>
      </div>
      {/* 4vp */}
      <div className="h-auto w-screen py-10 mt-20 relative">
        {Array.from(Array(3)).map((_, i) => (
          <div
            key={i}
            className=" w-[90%] md:w-[80%] lg:w-[70%] even:mt-10 last:mt-10 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          >
            <div className="flex items-center w-[100%] justify-start ">
              <h2 className="font-bold text-[29px] md:text-center lg:text-left bg-gradient-to-r from-[#FFA825] to-[#FF00F4] inline-block text-transparent bg-clip-text">
                Talent and attitude
              </h2>
            </div>
            <div className="flex col-span-2 items-center justify-end ">
              <p className=" text-left dark:text-white  pt-4 lg:pt-0 m-0 p-0 text-[17px] text-light font-light">
                Building a great product and a great company means building a
                team of talented people that can work well together and like to
                be challenged. Talent is a necessity to succeed, but if you
                prefer to work on your own, keep your head down and don’t take
                criticism well, then you will not be happy here. If the opposite
                is true, you like to work in a team, take initiative and stay
                humble, then come say hi!
              </p>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default AboutUS;
