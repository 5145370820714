import React, { FC, useEffect, useState } from "react";
import Applogo from "../../assets/png/Group 2 (1).png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../Button";
import { IoIosMenu } from "react-icons/io";
import NavModal from "./Modal";
import { links } from "./dataLink";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { GrPersonalComputer } from "react-icons/gr";
const Navbar: FC = () => {
  const navigate = useNavigate();
  const [showmodal, setshowmodal] = useState(false);
  const optionsNew = [
    {
      id: 1,
      text: "light",
    },
    {
      id: 2,
      text: "dark",
    },
    {
      id: 3,
      text: "system",
    },
  ];
  const options = ["light", "dark", "system"];
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );
  const element = document.documentElement;
  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");

  // console.log(darkQuery, "dark query");
  function onWindowMatch() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  }

  onWindowMatch();
  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;
      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  }, [theme]);
  return (
    <>
      {showmodal && (
        <NavModal closeModal={setshowmodal} showmodal={showmodal} />
      )}

      <div className="w-[90%] sm:w-[90%] md:w-[95%] lg:w-[90%] xl:max-x-xl xl:max-x-2xl z-[1000] fixed left-[50%]  translate-x-[-50%] mt-6 ">
        <div className="w-[100%] hidden items-center justify-end">
          <div className=" w-fit p-[1px] rounded-full dark:bg-lightblack bg-white dark:border-0 inset-6 justify-end items-center flex">
            {optionsNew.map(({ id, text }) => (
              <div
                key={id}
                onClick={() => setTheme(text)}
                className={`p-2 cursor-pointer m-1 rounded-full ${
                  theme === text ? "bg-primary shadow-sm " : "white"
                }`}
              >
                {text == "light" ? (
                  <MdOutlineLightMode
                    color={`${theme == text ? "white" : "#702C6D"}`}
                  />
                ) : text == "dark" ? (
                  <MdDarkMode
                    color={`${theme == text ? "white" : "#702C6D"}`}
                  />
                ) : (
                  <GrPersonalComputer
                    color={`${theme == text ? "white" : "#702C6D"}`}
                  />
                )}
              </div>
            ))}

            {/* {options.map((i) => (
              <button
                key={i}
                onClick={() => setTheme(i)}
                className={`p-3 border-[1px] mt-6 mx-4 ${
                  theme === i && "bg-black"
                }
            ${theme === i && "text-white"}
            `}
              >
                {/* {i} */}
            {/* 
              </button>
            ))} */}
            {/* <div className="dark:text-gray-100 dark:bg-slate-900 duration-100">
              The is the sample text
            </div> */}
          </div>
        </div>
        <div className=" sm:px-0 flex justify-between items-center ">
          <Link to="/" className="flex items-center justify-center">
            <div className="md:w-[40px] md:mt-4 mt-[10px] md:h-[40px] w-[40px] h-[40px]">
              <img src={Applogo} className="" alt="coral scale logo" />
            </div>
            <h2 className="text-[20px] dark:text-white duration-100 md:text-[25px] pl-1 font-bold m-0 p-0 text-primary">
              Coral Scale
            </h2>
          </Link>
          <div className="md:hidden sm:hidden hidden lg:block">
            {links.map(({ id, name, link }) => (
              <NavLink
                key={id}
                to={link}
                className=" dark:text-white p-4 m-2 text-primary font-[18px]"
              >
                {name}
              </NavLink>
            ))}
          </div>
          <div className="md:hidden lg:flex items-center hidden sm:hidden">
            <Button
              className="border-[1.5px] dark:border-lightblack dark:text-white dark:bg-lightblack  bg-white mr-4 cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center w-[126.26px] h-[42px] text-primary"
              text="Sign in"
              onClick={() => navigate("/signin")}
            />

            <Button
              className="text-white dark:text-white  dark:border-primary border-[1.5px] cursor-pointer border-primary rounded-[8.2px] flex items-center justify-center w-[126.26px] h-[42px] bg-primary"
              text="Sign Up"
              onClick={() => navigate("/signup")}
            />
          </div>
          <div
            className="lg:hidden flex items-center justify-center cursor-pointer"
            onClick={() => setshowmodal(true)}
          >
            <IoIosMenu size={30} className=" dark:text-white " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
