import React, { useEffect, useState } from "react"; // Import React
import { FC } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
interface Props {
  children: React.ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    // max-w-[1550px]
    // bg-[#FFFAFA]
    <div className="mx-auto w-[100%] dark:bg-black">
      <Navbar />
      <div className="">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
